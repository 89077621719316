.about_text {
  text-align: left;
  margin-left: 25px;
  margin-right: 0;
}

.about_intro {
  margin-bottom: 0px;
  width: fit-content;
}

.about_name {
  color: black;
  font-size: 10vh;
  margin-top: 0px;
  margin-bottom: 0px;
  width: fit-content;
}

.about_figs {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
}

.about_citations {
  height: fit-content;
  position: absolute;
  bottom: -70px;
  padding-left: 10px;
}
