ul {
  display: flex;
  gap: 2rem;
  font-size: 0.9rem;
  list-style: none;
  font-family: "Verdana";
}

li {
  cursor: pointer;
}

li:hover {
  text-decoration: underline overline;
}
