* {
  font-family: "Trebuchet MS";
  overflow-x: hidden;
}

a {
  transition: all 0.2s ease-in-out;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

a:hover {
  color: white;
}

section {
  display: grid;
}

#footer {
  height: -moz-fit-content;
  height: fit-content;
  background-color: coral;
  align-items: center;
}

#footer-note {
  text-align: center;
  margin: 8px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 65px;
}

.active {
  font-weight: bold;
}

.nav {
  display: flex;
  align-items: center;
  position: fixed;
  top: -5px;
  z-index: 2;
  height: 70px;
  min-height: 70px;
  width: 100%;
  background-color: coral;
  padding: 1;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
}

#about {
  background-color: antiquewhite;
  height: 30vh;
  padding-top: 5vh;
}

#resume {
  background-color: antiquewhite;
  height: -moz-fit-content;
  height: fit-content;
  padding: 3vh;
}

#contact {
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 20px;
  padding-top: 20px;
}

#projects {
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 25px;
}

#footer {
  height: -moz-fit-content;
  height: fit-content;
}

.resume_text {
  text-align: center;
  margin-top: 0vh;
}

.about_text {
  text-align: left;
  margin-left: 25px;
  margin-right: 0;
}

.about_intro {
  margin-bottom: 0px;
  width: -moz-fit-content;
  width: fit-content;
}

.about_name {
  color: black;
  font-size: 10vh;
  margin-top: 0px;
  margin-bottom: 0px;
  width: -moz-fit-content;
  width: fit-content;
}

.about_figs {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
}

.about_citations {
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  bottom: -70px;
  padding-left: 10px;
}

ul {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  font-size: 0.9rem;
  list-style: none;
  font-family: "Verdana";
}

li {
  cursor: pointer;
}

li:hover {
  text-decoration: underline overline;
}

.contact_header {
  text-align: center;
  margin-bottom: 30px;
  height: -moz-fit-content;
  height: fit-content;
}

.contact_text {
  font-weight: normal;
  margin-top: 0px;
  margin-bottom: 5px;
}

.horizontal {
  text-align: center;
}

.contact_box {
  border: 5px solid #faebd7;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  margin: auto;
}

div.socials {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
}

.icon {
  transition: all 0.2s ease-in-out !important;
}

.icon:hover {
  color: white !important;
}

.button {
  transition-duration: 0.4s;
}

.button:hover {
  background-color: aquamarine;
  color: white;
}

a {
  color: #111111;
  text-decoration: none;
}

.download_box {
  border: 5px solid white;
  background-color: #faebd7;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
}

Card {
  word-wrap: break-word;
  display: inline-block;
}

.MuiCard-root {
  border: 5px solid #faebd7;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.MuiTypography-root {
  font-family: "Trebuchet MS", "sans-serif" !important;
}

#left {
  text-align: left;
  margin-left: 50px;
  margin-right: 50px;
}

#center {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

