* {
  font-family: "Trebuchet MS";
  overflow-x: hidden;
}

a {
  transition: all 0.2s ease-in-out;
  width: fit-content;
  height: fit-content;
}

a:hover {
  color: white;
}

section {
  display: grid;
}

#footer {
  height: fit-content;
  background-color: coral;
  align-items: center;
}

#footer-note {
  text-align: center;
  margin: 8px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 65px;
}

.active {
  font-weight: bold;
}

.nav {
  display: flex;
  align-items: center;
  position: fixed;
  top: -5px;
  z-index: 2;
  height: 70px;
  min-height: 70px;
  width: 100%;
  background-color: coral;
  padding: 1;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
}

#about {
  background-color: antiquewhite;
  height: 30vh;
  padding-top: 5vh;
}

#resume {
  background-color: antiquewhite;
  height: fit-content;
  padding: 3vh;
}

#contact {
  height: fit-content;
  padding-bottom: 20px;
  padding-top: 20px;
}

#projects {
  height: fit-content;
  padding-bottom: 25px;
}

#footer {
  height: fit-content;
}

.resume_text {
  text-align: center;
  margin-top: 0vh;
}
