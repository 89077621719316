div.socials {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
}

.icon {
  transition: all 0.2s ease-in-out !important;
}

.icon:hover {
  color: white !important;
}
